//translation
import {tl} from "framework/utils/Translator"
import Cookies from 'js-cookie'

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import HumanIcon from "@material-ui/icons/AccessibilityNew";
import EmployeeIcon from "@material-ui/icons/AccountBox";
import UserIcon from "@material-ui/icons/AccountCircle";
import UserGroupIcon from "@material-ui/icons/Group";
import BranchIcon from "@material-ui/icons/Store";
import CountryIcon from "@material-ui/icons/Flag";
import LocationIcon from "@material-ui/icons/LocationOn";
import CityIcon from "@material-ui/icons/LocationCity";
import JournalIcon from "@material-ui/icons/Book";
import ItemIcon from "@material-ui/icons/CardGiftcard";
import ItemRequestIcon from "@material-ui/icons/HowToVote";
import ExchangeIcon from "@material-ui/icons/Cached";
import NodeIcon from "@material-ui/icons/MyLocation";
import UnitIcon from "@material-ui/icons/GroupWork";
import UnitConversionIcon from "@material-ui/icons/GroupWorkOutlined";
import PaymentTypeIcon from "@material-ui/icons/CreditCard";
import PaymentIcon from "@material-ui/icons/Payment";
import GiroIcon from "@material-ui/icons/Tab";
import ProjectIcon from "@material-ui/icons/Assignment";
import SettingsIcon from "@material-ui/icons/Settings"
import DebugIcon from "@material-ui/icons/Code"
import FiscalIcon from "@material-ui/icons/CalendarToday"
import CurrencyIcon from "@material-ui/icons/AttachMoney"
import PolicyIcon from "@material-ui/icons/Beenhere"
import CategoryIcon from "@material-ui/icons/Category"
import AccountIcon from "@material-ui/icons/AccountBalance"
import WalletIcon from "@material-ui/icons/AccountBalanceWallet"
import MenuIcon from "@material-ui/icons/List"
import PurchaseIcon from "@material-ui/icons/ShoppingCart"
import InstantPurchaseIcon from "@material-ui/icons/ShoppingCartOutlined"
import SalesIcon from "@material-ui/icons/BusinessCenter"
import SalesOrderIcon from "@material-ui/icons/Assignment"
import SalesOrderExtendIcon from "@material-ui/icons/AssignmentReturned"
import CustomerIcon from "@material-ui/icons/Person"
import InvoiceIcon from "@material-ui/icons/ListAlt"
import ReturnIcon from "@material-ui/icons/Undo"
import ExcelIcon from "@material-ui/icons/GridOn"
import AccessRightIcon from "@material-ui/icons/ControlCamera"
import ShippingIcon from "@material-ui/icons/LocalShipping"
import GoodsReceiveIcon from "@material-ui/icons/Archive"
import POIcon from "@material-ui/icons/AddShoppingCart"
import CollectiveInvoiceIcon from "@material-ui/icons/LibraryBooks"
import WriteOffIcon from "@material-ui/icons/LayersClear"
import MaterialReleaseIcon from "@material-ui/icons/FlipToFront"
import ProductionIcon from "@material-ui/icons/LinearScale"
import ProductionResultIcon from "@material-ui/icons/Subtitles"
import AdjustmentIcon from "@material-ui/icons/CheckCircleOutline"
import RelocationIcon from "@material-ui/icons/Room"
import TaxIcon from "@material-ui/icons/Title"
import BrandIcon from "@material-ui/icons/Loyalty"
import AdditionalChargeIcon from "@material-ui/icons/AddCircleOutline"
import TagIcon from "@material-ui/icons/Label"
import PromotionIcon from "@material-ui/icons/LocalOfferOutlined"
import SalesAdjustmentIcon from "@material-ui/icons/Input"
import VendorIcon from "@material-ui/icons/Unarchive"
import InstantSalesIcon from "@material-ui/icons/ShoppingBasket"
import BankIcon from "@material-ui/icons/AccountBalance"
import WithdrawIcon from "@material-ui/icons/SystemUpdateOutlined"
import ImportIcon from "@material-ui/icons/CloudUpload"
import PriceIcon from "@material-ui/icons/AttachMoneyOutlined"
import RackIcon from "@material-ui/icons/ViewAgendaOutlined"
import MemberIcon from "@material-ui/icons/CardMembership"
import PackageIcon from "@material-ui/icons/VideoLabel"
import CourierIcon from "@material-ui/icons/LocalShippingOutlined"
import ProcurementIcon from "@material-ui/icons/LibraryAdd"
import ItemRequestCompletedIcon from "@material-ui/icons/CheckCircleOutline"
import ItemRequestCancelledIcon from "@material-ui/icons/CancelOutlined"

//activities
import AccountingJournalEntriesActivity from 'app/modules/Accounting/activities/Accounting/AccountingJournalEntriesActivity'
import AccountingManualJournalEntriesActivity from 'app/modules/Accounting/activities/Accounting/AccountingManualJournalEntriesActivity'
import AccountingExchangeRateActivity from 'app/modules/Accounting/activities/Accounting/AccountingExchangeRateActivity'
import AccountingPartnerActivity from 'app/modules/Accounting/activities/Accounting/AccountingPartnerActivity'
import AccountingBranchActivity from 'app/modules/Accounting/activities/Accounting/AccountingBranchActivity'
import AccountingReportGeneralLedgerActivity from 'app/modules/Accounting/activities/AccountingReport/AccountingReportGeneralLedgerActivity'
import AccountingUserAccountBalanceReportActivity from 'app/modules/Accounting/activities/AccountingReport/AccountingUserAccountBalanceReportActivity'
import AccountingReportProfitandLossActivity from 'app/modules/Accounting/activities/AccountingReport/AccountingReportProfitandLossActivity'
import AccountingReportBalanceSheetActivity from 'app/modules/Accounting/activities/AccountingReport/AccountingReportBalanceSheetActivity'
import AccountingReportNegativeValuationActivity from "app/modules/Accounting/activities/AccountingReport/AccountingNegativeInventoryValuationActivity"
import TaxNonTaxReportActivity from 'app/modules/Accounting/activities/AccountingReport/AccountingReportTaxNonTaxActivity'
import AccountingInventoryValuationActivity from 'app/modules/Accounting/activities/AccountingReport/AccountingInventoryValuationActivity'
import AccountingConfigurationAccountActivity from 'app/modules/Accounting/activities/AccountingConfiguration/AccountingConfigurationAccountActivity'
import AccountingConfigurationAccountGroupActivity from 'app/modules/Accounting/activities/AccountingConfiguration/AccountingConfigurationAccountGroupActivity'
import AccountingConfigurationItemActivity from 'app/modules/Accounting/activities/AccountingConfiguration/AccountingConfigurationItemActivity'
import AccountingConfigurationItemCategoryActivity from 'app/modules/Accounting/activities/AccountingConfiguration/AccountingConfigurationItemCategoryActivity'
import AccountingConfigurationBrandActivity from 'app/modules/Accounting/activities/AccountingConfiguration/AccountingConfigurationBrandActivity'
import AccountingConfigurationAdditionalChargeActivity from 'app/modules/Accounting/activities/AccountingConfiguration/AccountingConfigurationAdditionalChargeActivity'
import AccountingConfigurationTagActivity from 'app/modules/Accounting/activities/AccountingConfiguration/AccountingConfigurationTagActivity'
import AccountingConfigurationUnitActivity from 'app/modules/Accounting/activities/AccountingConfiguration/AccountingConfigurationUnitActivity'
import AccountingConfigurationPaymentTypesActivity from 'app/modules/Accounting/activities/AccountingConfiguration/AccountingConfigurationPaymentTypesActivity'
import AccountingConfigurationTaxesActivity from 'app/modules/Accounting/activities/AccountingConfiguration/AccountingConfigurationTaxesActivity'
import AccountingConfigurationPeriodActivity from 'app/modules/Accounting/activities/AccountingConfiguration/AccountingConfigurationPeriodActivity'
import AccountingConfigurationFiscalActivity from 'app/modules/Accounting/activities/AccountingConfiguration/AccountingConfigurationFiscalActivity'
import AccountingConfigurationPolicyActivity from 'app/modules/Accounting/activities/AccountingConfiguration/AccountingConfigurationPolicyActivity'
import AccountingConfigurationCurrencyActivity from 'app/modules/Accounting/activities/AccountingConfiguration/AccountingConfigurationCurrencyActivity'
import FinanceARDeliveryOrderActivity from 'app/modules/Accounting/activities/FinanceAR/FinanceARDeliveryOrderActivity'
import FinanceARInvoiceActivity from 'app/modules/Accounting/activities/FinanceAR/FinanceARInvoiceActivity'
import FinanceARCollectiveInvoiceActivity from 'app/modules/Accounting/activities/FinanceAR/FinanceARCollectiveInvoiceActivity'
import FinanceARDebitNoteActivity from 'app/modules/Accounting/activities/FinanceAR/FinanceARDebitNoteActivity'
import FinanceARReceiveActivity from 'app/modules/Accounting/activities/FinanceAR/FinanceARReceiveActivity'
import FinanceARReturnActivity from 'app/modules/Accounting/activities/FinanceAR/FinanceARReturnActivity'
import FinanceARPayableClaimActivity from 'app/modules/Accounting/activities/FinanceAR/FinanceARPayableClaim'
import FinanceARWriteOffActivity from 'app/modules/Accounting/activities/FinanceAR/FinanceARWriteOffActivity'
import FinanceARAdvanceReceiveActivity from 'app/modules/Accounting/activities/FinanceAR/FinanceARAdvanceReceiveActivity'
import FinanceARGiroActivity from 'app/modules/Accounting/activities/FinanceAR/FinanceARGiroActivity'
import FinanceARCustomerActivity from 'app/modules/Accounting/activities/FinanceAR/FinanceARCustomerActivity'
import FinanceAPGoodsReceiveNoteActivity from 'app/modules/Accounting/activities/FinanceAP/FinanceAPGoodsReceiveNoteActivity'
import FinanceAPInvoiceActivity from 'app/modules/Accounting/activities/FinanceAP/FinanceAPInvoiceActivity'
import FinanceAPCollectiveInvoiceActivity from 'app/modules/Accounting/activities/FinanceAP/FinanceAPCollectiveInvoiceActivity'
import FinanceAPCreditNoteActivity from 'app/modules/Accounting/activities/FinanceAP/FinanceAPCreditNoteActivity'
import FinanceAPPaymentActivity from 'app/modules/Accounting/activities/FinanceAP/FinanceAPPaymentActivity'
import FinanceAPReturnActivity from 'app/modules/Accounting/activities/FinanceAP/FinanceAPReturnActivity'
import FinanceAPPurchaseWriteOffActivity from 'app/modules/Accounting/activities/FinanceAP/FinanceAPPurchaseWriteOffActivity'
import FinanceAPAdvancePaymentActivity from 'app/modules/Accounting/activities/FinanceAP/FinanceAPAdvancePaymentActivity'
import FinanceAPGiroActivity from 'app/modules/Accounting/activities/FinanceAP/FinanceAPGiroActivity'
import FinanceAPSupplierActivity from 'app/modules/Accounting/activities/FinanceAP/FinanceAPSupplierActivity'
import FinanceAPWithdrawRequestActivity from 'app/modules/Accounting/activities/FinanceAP/FinanceAPWithdrawRequestActivity'
import ARReportInvoiceActivity from 'app/modules/Accounting/activities/ARReport/ARReportInvoiceActivity'
import ARReportReceiveActivity from 'app/modules/Accounting/activities/ARReport/ARReportReceiveActivity'
import ARReportOutstandingGiroActivity from 'app/modules/Accounting/activities/ARReport/ARReportOutstandingGiroActivity'
import ARReportOutstandingDeliveryOrderActivity from 'app/modules/Accounting/activities/ARReport/ARReportOutstandingDeliveryOrderActivity'
import ARReportOutstandingInvoiceActivity from 'app/modules/Accounting/activities/ARReport/ARReportOutstandingInvoiceActivity'
import ARReportCustomerCreditLimitActivity from 'app/modules/Accounting/activities/ARReport/ARReportCustomerCreditLimitActivity'
import ARReportReturnActivity from 'app/modules/Accounting/activities/ARReport/ARReportReturnActivity'
import APReportInvoiceActivity from 'app/modules/Accounting/activities/APReport/APReportInvoiceActivity'
import APReportPaymentActivity from 'app/modules/Accounting/activities/APReport/APReportPaymentActivity'
import APReportOutstandingGiroActivity from 'app/modules/Accounting/activities/APReport/APReportOutstandingGiroActivity'
import APReportOutstandingGoodsReceiveNoteActivity from 'app/modules/Accounting/activities/APReport/APReportOutstandingGoodsReceiveNoteActivity'
import APReportOutstandingInvoiceActivity from 'app/modules/Accounting/activities/APReport/APReportOutstandingInvoiceActivity'
import APReportReturnActivity from 'app/modules/Accounting/activities/APReport/APReportReturnActivity'
import FinanceConfigTermActivity from 'app/modules/Accounting/activities/FinanceConfig/FinanceConfigTermActivity'
import FinanceConfigTokenMovementActivity from 'app/modules/Accounting/activities/FinanceConfig/FinanceConfigTokenMovementActivity'
import WarehouseGoodsReceiveNoteActivity from 'app/modules/Accounting/activities/Warehouse/WarehouseGoodsReceiveNoteActivity'
import WarehouseDeliveryOrderActivity from 'app/modules/Accounting/activities/Warehouse/WarehouseDeliveryOrderActivity'
import WarehouseCrossBranchDeliveryOrderActivity from 'app/modules/Accounting/activities/Warehouse/WarehouseCrossBranchDeliveryOrderActivity'
import WarehouseSalesReturnActivity from 'app/modules/Accounting/activities/Warehouse/WarehouseSalesReturnActivity'
import WarehousePurchaseReturnActivity from 'app/modules/Accounting/activities/Warehouse/WarehousePurchaseReturnActivity'
import WarehouseMaterialReleaseActivity from 'app/modules/Accounting/activities/Warehouse/WarehouseMaterialReleaseActivity'
import WarehouseInventoryIssueActivity from 'app/modules/Accounting/activities/Warehouse/WarehouseInventoryIssueActivity'
import WarehouseInventoryAdjustmentActivity from 'app/modules/Accounting/activities/Warehouse/WarehouseInventoryAdjustmentActivity'
import WarehouseInventoryRelocationActivity from 'app/modules/Accounting/activities/Warehouse/WarehouseInventoryRelocationActivity'
import WarehouseReportItemActivity from 'app/modules/Accounting/activities/WarehouseReport/WarehouseReportItemActivity'
import WarehouseReportDailyStockActivity from 'app/modules/Accounting/activities/WarehouseReport/WarehouseReportDailyStockActivity'
import WarehouseReportStockActivity from 'app/modules/Accounting/activities/WarehouseReport/WarehouseReportStockActivity'
import WarehouseReportGoodsReceiveActivity from 'app/modules/Accounting/activities/WarehouseReport/WarehouseReportGoodsReceiveActivity'
import WarehouseReportDeliveryOrderActivity from 'app/modules/Accounting/activities/WarehouseReport/WarehouseReportDeliveryOrderActivity'
import WarehouseReportReturnActivity from 'app/modules/Accounting/activities/WarehouseReport/WarehouseReportReturnActivity'
import WarehouseReportInventoryConversionActivity from 'app/modules/Accounting/activities/WarehouseReport/WarehouseReportInventoryConversionActivity'
import WarehouseConfigLocationActivity from 'app/modules/Accounting/activities/WarehouseConfig/WarehouseConfigLocationActivity'
import WarehouseConfigWarehouseActivity from 'app/modules/Accounting/activities/WarehouseConfig/WarehouseConfigWarehouseActivity'
import WarehouseConfigItemActivity from 'app/modules/Accounting/activities/WarehouseConfig/WarehouseConfigItemActivity'
import WarehouseConfigItemCategoryActivity from 'app/modules/Accounting/activities/WarehouseConfig/WarehouseConfigItemCategoryActivity'
import WarehouseConfigUnitActivity from 'app/modules/Accounting/activities/WarehouseConfig/WarehouseConfigUnitActivity'
import SalesSalesOrderActivity from 'app/modules/Accounting/activities/Sales/SalesSalesOrderActivity'
import SalesOrderExtendActivity from 'app/modules/Accounting/activities/Sales/SalesOrderExtendActivity'
import SalesDeliveryOrderActivity from 'app/modules/Accounting/activities/Sales/SalesDeliveryOrderActivity'
import SalesCustomerActivity from 'app/modules/Accounting/activities/Sales/SalesCustomerActivity'
import SalesItemActivity from 'app/modules/Accounting/activities/Sales/SalesItemActivity'
import SalesItemCategoryActivity from 'app/modules/Accounting/activities/Sales/SalesItemCategoryActivity'
import SalesUnitActivity from 'app/modules/Accounting/activities/Sales/SalesUnitActivity'
import ItemPricelistActivity from 'app/modules/Accounting/activities/SalesReport/ItemPricelistActivity'
import PurchasingPurchaseOrderActivity from 'app/modules/Accounting/activities/Purchasing/PurchasingPurchaseOrderActivity'
import PurchasingGoodsReceiveNoteActivity from 'app/modules/Accounting/activities/Purchasing/PurchasingGoodsReceiveNoteActivity'
import PurchasingVendorDeliveryRequestActivity from 'app/modules/Accounting/activities/Purchasing/PurchasingVendorDeliveryRequestActivity'
import PurchasingSupplierActivity from 'app/modules/Accounting/activities/Purchasing/PurchasingSupplierActivity'
import PurchasingItemActivity from 'app/modules/Accounting/activities/Purchasing/PurchasingItemActivity'
import PurchasingItemCategoryActivity from 'app/modules/Accounting/activities/Purchasing/PurchasingItemCategoryActivity'
import PurchasingUnitActivity from 'app/modules/Accounting/activities/Purchasing/PurchasingUnitActivity'
import PurchasingReportOutstandingPurchaseOrderActivity from 'app/modules/Accounting/activities/PurchasingReport/PurchasingReportOutstandingPurchaseOrderActivity'
import ProjectDeliveryRequestActivity from 'app/modules/Accounting/activities/Project/ProjectDeliveryRequestActivity'
import ProjectProjectActivity from 'app/modules/Accounting/activities/Project/ProjectProjectActivity'
import ProductionItemRequestActivity from 'app/modules/Accounting/activities/Production/ProductionItemRequestActivity'
import ProductionProductionResultActivity from 'app/modules/Accounting/activities/Production/ProductionProductionResultActivity'
import HREmployeeActivity from 'app/modules/Accounting/activities/HR/HREmployeeActivity'
import AdministrationUserActivity from 'app/modules/Accounting/activities/Administration/AdministrationUserActivity'
import AdministrationUserGroupActivity from 'app/modules/Accounting/activities/Administration/AdministrationUserGroupActivity'
import AdministrationBranchActivity from 'app/modules/Accounting/activities/Administration/AdministrationBranchActivity'
import AdministrationCityActivity from 'app/modules/Accounting/activities/Administration/AdministrationCityActivity'
import AdministrationProvinceActivity from 'app/modules/Accounting/activities/Administration/AdministrationProvinceActivity'
import AdministrationCountryActivity from 'app/modules/Accounting/activities/Administration/AdministrationCountryActivity'
import AdministrationNodeActivity from 'app/modules/Accounting/activities/Administration/AdministrationNodeActivity'
import AdministrationMenuActivity from 'app/modules/Accounting/activities/Administration/AdministrationMenuActivity'
import AdministrationMenuCategoryActivity from 'app/modules/Accounting/activities/Administration/AdministrationMenuCategoryActivity'
import AdministrationAccessRightActivity from 'app/modules/Accounting/activities/Administration/AdministrationAccessRightActivity'
import AdministrationAccessRightCategoryActivity from 'app/modules/Accounting/activities/Administration/AdministrationAccessRightCategoryActivity'
import DebugItemValueActivity from 'app/modules/Accounting/activities/Debug/DebugItemValueActivity'
import DebugInventoryActivity from 'app/modules/Accounting/activities/Debug/DebugInventoryActivity'
import DebugInventoryMovePlanActivity from 'app/modules/Accounting/activities/Debug/DebugInventoryMovePlanActivity'
import InstantSales from 'app/modules/Accounting/activities/FinanceAR/FinanceARInstantSales'
import BulkPaymentActivity from 'app/modules/Accounting/activities/FinanceAP/FinanceAPBulkPaymentActivity'
import BankActivity from 'app/modules/Accounting/activities/AccountingConfiguration/AccountingConfigurationBankActivity'
import ItemImportActivity from 'app/modules/Accounting/activities/AccountingConfiguration/AccountingConfigurationItemImportActivity'
import WarehouseItemImportActivity from 'app/modules/Accounting/activities/WarehouseConfig/WarehouseItemImport'
import PromotionActivity from 'app/modules/Accounting/activities/Promotion/PromotionActivity'


import FinanceARConsignmentSales from 'app/modules/Accounting/activities/FinanceAR/FinanceARConsignmentSales'
import FinanceAPConsignmentPurchase from 'app/modules/Accounting/activities/FinanceAP/FinanceAPConsignmentPurchase'
import InstantSalesReportActivity from "app/modules/Accounting/activities/SalesReport/InstantSalesReportActivity"
import ItemStatisticReportActivity from "app/modules/Accounting/activities/SalesReport/ItemStatisticReportActivity"
import WarehouseInventoryConversionActivity from "app/modules/Accounting/activities/Warehouse/WarehouseInventoryConversion";
import CashierReportActivity from "app/modules/Accounting/activities/SalesReport/CashierReportActivity";
import AccountingConfigurationItemPriceActivity from "./activities/AccountingConfiguration/AccountingConfigurationItemPriceActivity";
import FinanceAPInstantPurchase from "./activities/FinanceAP/FinanceAPInstantPurchase";
import InstantSalesView from "./activities/InstantTransaction/InstantSalesViewActivity";
import InstantPurchaseView from "./activities/InstantTransaction/InstantPurchaseViewActivity";
import InstantPurchaseReportActivity from "./activities/PurchasingReport/InstantPurchaseReportActivity";
import InstantPurchaseItemPricesReportActivity from "./activities/PurchasingReport/InstantPurchaseItemPricesReportActivity";
import AccountingFundHandoverActivity from "./activities/Accounting/AccountingFundHandoverActivity";
import AccountingConfigurationRackActivity from "./activities/AccountingConfiguration/AccountingConfigurationRackActivity";
import CommissionReportActivity from "app/modules/Accounting/activities/SalesReport/CommissionReportActivity";
import WarehouseReportInventoryRelocationActivity from "./activities/WarehouseReport/WarehouseReportInventoryRelocationActivity";
import AccountingAssetActivity from "./activities/Accounting/AccountingAssetActivity";
import ARReportAgingActivity from "./activities/ARReport/ARReportAgingActivity";
import APReportAgingActivity from "./activities/APReport/APReportAgingActivity";
import ARDetailedVoucherReportActivity from "./activities/ARReport/ARDetailedVoucherReportActivity";
import APDetailedVoucherReportActivity from "./activities/APReport/APDetailedVoucherReportActivity";
import ARInvoiceMovementReportActivity from "./activities/ARReport/ARInvoiceMovementReportActivity";
import APInvoiceMovementReportActivity from "./activities/APReport/APInvoiceMovementReportActivity";
import AccountingTrialBalanceActivity from "./activities/AccountingReport/AccountingTrialBalanceActivity";
import SalesMemberActivity from "./activities/Sales/SalesMemberActivity";
import ItemPriceSalesPurchase from "./activities/SalesReport/ItemPriceSalesPurchase";
import FinanceAPPurchaseOrderAdvancedActivity from "./activities/FinanceAP/FinanceAPPurchaseOrderAdvancedActivity";
import AccountingConfigurationPackagingActivity from "./activities/AccountingConfiguration/AccountingConfigurationPackagingActivity";
import AccountingConfigurationItemOptionActivity from "./activities/AccountingConfiguration/AccountingConfigurationItemOptionActivity";
import ProcurementReportActivity from "./activities/PurchasingReport/ProcurementReportActivity";
import AccountingConfigurationCourierActivity from "./activities/AccountingConfiguration/AccountingConfigurationCourierActivity";
import WarningLogReportActivity from "./activities/SalesReport/WarningLogReportActivity"
import PricelistReportActivity from "./activities/SalesReport/PricelistReportActivity"
import ProductionItemRequestCompletedActivity from "./activities/Production/ProductionItemRequestCompletedActivity";
import ProductionItemRequestCancelledActivity from "./activities/Production/ProductionItemRequestCancelledActivity";

var Routes = [
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Accounting"),
	    menu		: "ACCT",
	    icon 		: AccountIcon,
	    state		: "ACCT",
	    views		:[
			{
				path		: "/accounting-journal-entries/:id?",
				name		: tl(Cookies.get("lang"),"AccountingJournalEntries"),
				menu		: "ACCT01",
				icon 		: JournalIcon,
				component 	: AccountingJournalEntriesActivity,
				layout		: "/admin"
			},
			{
				path		: "/accounting-manual-journal-entries/:id?",
				name		: tl(Cookies.get("lang"),"AccountingManualJournalEntries"),
				menu		: "ACCT01",
				icon 		: JournalIcon,
				component 	: AccountingManualJournalEntriesActivity,
				layout		: "/admin"
			},
			{
				path		: "/accounting-asset/:id?",
				name		: tl(Cookies.get("lang"),"Asset"),
				menu		: "ACCTAST",
				icon 		: JournalIcon,
				component 	: AccountingAssetActivity,
				layout		: "/admin"
			},
			{
				path		: "/accounting-exchange-rate/:id?",
				name		: tl(Cookies.get("lang"),"AccountingExchangeRate"),
				menu		: "ACCT02",
				icon 		: ExchangeIcon,
				component 	: AccountingExchangeRateActivity,
				layout		: "/admin"
			},
			{
				path		: "/accounting-partner/:id?",
				name		: tl(Cookies.get("lang"),"AccountingPartner"),
				menu		: "ACCT03",
				icon 		: CustomerIcon,
				component 	: AccountingPartnerActivity,
				layout		: "/admin"
			},
			{
				path		: "/accounting-branch/:id?",
				name		: tl(Cookies.get("lang"),"AccountingBranch"),
				menu		: "ACCT04",
				icon 		: BranchIcon,
				component 	: AccountingBranchActivity,
				layout		: "/admin"
			},
			{
				path		: "/accounting-fund-handover/:id?",
				name		: tl(Cookies.get("lang"),"FundHandover"),
				menu		: "ACCT05",
				icon 		: WithdrawIcon,
				component 	: AccountingFundHandoverActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Accounting Report"),
	    menu		: "ACCTREPORT",
	    icon 		: AccountIcon,
	    state		: "ACCTREPORT",
	    views		:[
			{
				path		: "/accounting-report-general-ledger/:id?",
				name		: tl(Cookies.get("lang"),"AccountingReportGeneralLedger"),
				menu		: "ACCTREPORT01",
				icon 		: ExcelIcon,
				component 	: AccountingReportGeneralLedgerActivity,
				layout		: "/admin",
				widget 		: "modules/Accounting/reports/GeneralLedger/AccountingReportGeneralLedger"
			},
			{
				path		: "/accounting-report-profitand-loss",
				name		: tl(Cookies.get("lang"),"AccountingReportProfitandLoss"),
				menu		: "ACCTREPORT02",
				icon 		: ExcelIcon,
				component 	: AccountingReportProfitandLossActivity,
				layout		: "/admin",
				widget 		: "modules/Accounting/reports/ProfitAndLoss/AccountingReportProfitandLoss"
			},
			{
				path		: "/accounting-report-balance-sheet",
				name		: tl(Cookies.get("lang"),"AccountingReportBalanceSheet"),
				menu		: "ACCTREPORT03",
				icon 		: ExcelIcon,
				component 	: AccountingReportBalanceSheetActivity,
				layout		: "/admin",
				widget		: "modules/Accounting/reports/BalanceSheet/AccountingReportBalanceSheet"
			},
			{
				path		: "/accounting-report-trial-balance",
				name		: tl(Cookies.get("lang"),"TrialBalance"),
				menu		: "ACCTREPORT08",
				icon 		: ExcelIcon,
				component 	: AccountingTrialBalanceActivity,
				layout		: "/admin",
				widget 		: 'modules/Accounting/reports/TrialBalance/TrialBalance'
			},
			{
				path		: "/accounting-inv-valuation-report",
				name		: tl(Cookies.get("lang"),"AccountingInventoryValuationReport"),
				menu		: "ACCTREPORT05",
				icon 		: ExcelIcon,
				component 	: AccountingInventoryValuationActivity,
				layout		: "/admin",
				widget		: "modules/Accounting/reports/InventoryValuationReport/InventoryValuationReport"
			},
			{
				path		: "/accounting-negative-inv-valuation-report",
				name		: tl(Cookies.get("lang"),"AccountingNegativeInventoryValuationReport"),
				menu		: "ACCTREPORT07",
				icon 		: ExcelIcon,
				component 	: AccountingReportNegativeValuationActivity,
				layout		: "/admin",
				widget 		: "modules/Accounting/reports/NegativeInventoryValuationReport/NegativeInventoryValuationReport"
			},
			{
				path		: "/accounting-report-tax-non-tax",
				name		: tl(Cookies.get("lang"),"AccountingReportTaxNonTaxReport"),
				menu		: "ACCTREPORT04",
				icon 		: ExcelIcon,
				component 	: TaxNonTaxReportActivity,
				layout		: "/admin",
				widget		: "modules/Accounting/reports/TaxNonTaxReport/TaxNonTaxReport"
			},
			{
				path		: "/accounting-report-user-account-balance",
				name		: tl(Cookies.get("lang"),"UserAccountBalanceReport"),
				menu		: "ACCTREPORT06",
				icon 		: ExcelIcon,
				component 	: AccountingUserAccountBalanceReportActivity,
				layout		: "/admin",
				widget		: "modules/Accounting/reports/UserAccountBalanceReport/UserAccountBalanceReport"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Accounting Configuration"),
	    menu		: "ACCTCONF",
	    icon 		: AccountIcon,
	    state		: "ACCTCONF",
	    views		:[
			{
				path		: "/accounting-configuration-account/:id?",
				name		: tl(Cookies.get("lang"),"AccountingConfigurationAccount"),
				menu		: "ACCTCONF03",
				icon 		: AccountIcon,
				component 	: AccountingConfigurationAccountActivity,
				layout		: "/admin"
			},
			{
				path		: "/accounting-configuration-account-group/:id?",
				name		: tl(Cookies.get("lang"),"AccountingConfigurationAccountGroup"),
				menu		: "ACCTCONFGRP",
				icon 		: DashboardIcon,
				component 	: AccountingConfigurationAccountGroupActivity,
				layout		: "/admin"
			},
			{	
				path		: "/accounting-configuration-item/:id?",
				name		: tl(Cookies.get("lang"),"AccountingConfigurationItem"),
				menu		: "ACCTCONF04",
				icon 		: ItemIcon,
				component 	: AccountingConfigurationItemActivity,
				layout		: "/admin",
				widget		: "components/Accounting/Item/ItemBrowser"
			},
			{
				path		: "/item-price-config",
				name		: tl(Cookies.get("lang"),"ItemPriceConfig"),
				// menu		: "ACCTCONFIMPT",
				menu		: "ACCTCONFPRC",
				icon 		: PriceIcon,
				component 	: AccountingConfigurationItemPriceActivity,
				layout		: "/admin"
			},
			{
				path		: "/accounting-configuration-item-category/:id?",
				name		: tl(Cookies.get("lang"),"AccountingConfigurationItemCategory"),
				menu		: "ACCTCONF05",
				icon 		: CategoryIcon,
				component 	: AccountingConfigurationItemCategoryActivity,
				layout		: "/admin"
			},
			{
				path		: "/accounting-configuration-brand/:id?",
				name		: tl(Cookies.get("lang"),"AccountingConfigurationBrand"),
				menu		: "ACCTCONF11",
				icon 		: BrandIcon,
				component 	: AccountingConfigurationBrandActivity,
				layout		: "/admin"
			},
			{
				path		: "/accounting-configuration-additional-charge/:id?",
				name		: tl(Cookies.get("lang"),"AccountingConfigurationAdditionalCharge"),
				menu		: "ACCTCONF12",
				icon 		: AdditionalChargeIcon,
				component 	: AccountingConfigurationAdditionalChargeActivity,
				layout		: "/admin"
			},
			{
				path		: "/promotion/:id?",
				name		: tl(Cookies.get("lang"),"Promotion"),
				menu		: "ACCTCONFPRMT",
				icon 		: PromotionIcon,
				component 	: PromotionActivity,
				layout		: "/admin"
			},
			{
				path		: "/accounting-configuration-tag/:id?",
				name		: tl(Cookies.get("lang"),"AccountingConfigurationTag"),
				menu		: "ACCTCONF13",
				icon 		: TagIcon,
				component 	: AccountingConfigurationTagActivity,
				layout		: "/admin"
			},
			{
				path		: "/accounting-configuration-unit/:id?",
				name		: tl(Cookies.get("lang"),"AccountingConfigurationUnit"),
				menu		: "ACCTCONF10",
				icon 		: UnitIcon,
				component 	: AccountingConfigurationUnitActivity,
				layout		: "/admin"
			},
			{
				path		: "/item-option/:id?",
				name		: tl(Cookies.get("lang"),"ItemOption"),
				menu		: "ACCTCONFITOP",
				icon 		: MenuIcon,
				component 	: AccountingConfigurationItemOptionActivity,
				layout		: "/admin"
			},
			{
				path		: "/accounting-configuration-packaging/:id?",
				name		: tl(Cookies.get("lang"),"Packaging"),
				menu		: "ACCTCONFPACK",
				icon 		: PackageIcon,
				component 	: AccountingConfigurationPackagingActivity,
				layout		: "/admin"
			},
			{
				path		: "/accounting-configuration-payment-types/:id?",
				name		: tl(Cookies.get("lang"),"AccountingConfigurationPaymentTypes"),
				menu		: "ACCTCONF01",
				icon 		: PaymentTypeIcon,
				component 	: AccountingConfigurationPaymentTypesActivity,
				layout		: "/admin"
			},
			{
				path		: "/accounting-configuration-taxes/:id?",
				name		: tl(Cookies.get("lang"),"AccountingConfigurationTaxes"),
				menu		: "ACCTCONF02",
				icon 		: TaxIcon,
				component 	: AccountingConfigurationTaxesActivity,
				layout		: "/admin"
			},
			{
				path		: "/accounting-configuration-period/:id?",
				name		: tl(Cookies.get("lang"),"AccountingConfigurationPeriod"),
				menu		: "ACCTCONF06",
				icon 		: FiscalIcon,
				component 	: AccountingConfigurationPeriodActivity,
				layout		: "/admin"
			},
			{
				path		: "/accounting-configuration-fiscal/:id?",
				name		: tl(Cookies.get("lang"),"AccountingConfigurationFiscal"),
				menu		: "ACCTCONF07",
				icon 		: FiscalIcon,
				component 	: AccountingConfigurationFiscalActivity,
				layout		: "/admin"
			},
			{
				path		: "/accounting-configuration-policy/:id?",
				name		: tl(Cookies.get("lang"),"AccountingConfigurationPolicy"),
				menu		: "ACCTCONF08",
				icon 		: PolicyIcon,
				component 	: AccountingConfigurationPolicyActivity,
				layout		: "/admin"
			},
			{
				path		: "/accounting-configuration-currency/:id?",
				name		: tl(Cookies.get("lang"),"AccountingConfigurationCurrency"),
				menu		: "ACCTCONF09",
				icon 		: CurrencyIcon,
				component 	: AccountingConfigurationCurrencyActivity,
				layout		: "/admin"
			},
			{
				path		: "/accounting-configuration-bank/:id?",
				name		: tl(Cookies.get("lang"),"Bank"),
				menu		: "ACCTCONF14",
				icon 		: BankIcon,
				component 	: BankActivity,
				layout		: "/admin"
			},
			{
				path		: "/item-import",
				name		: tl(Cookies.get("lang"),"ItemImport"),
				menu		: "ACCTCONFIMPT",
				icon 		: ImportIcon,
				component 	: ItemImportActivity,
				layout		: "/admin"
			},
			{
				path		: "/rack",
				name		: tl(Cookies.get("lang"),"Rack"),
				menu		: "ACCTCONFRACK",
				icon 		: RackIcon,
				component 	: AccountingConfigurationRackActivity,
				layout		: "/admin"
			},
			{
				path		: "/courier/:id?",
				name		: tl(Cookies.get("lang"),"Courier"),
				menu		: "ACCTCONFCOURIER",
				icon 		: CourierIcon,
				component 	: AccountingConfigurationCourierActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Finance AR"),
	    menu		: "AR",
	    icon 		: WalletIcon,
	    state		: "AR",
	    views		:[
			{
				path		: "/finance-a-r-delivery-order/:id?",
				name		: tl(Cookies.get("lang"),"FinanceARDeliveryOrder"),
				menu		: "AR01",
				icon 		: ShippingIcon,
				component 	: FinanceARDeliveryOrderActivity,
				layout		: "/admin"
			},
			{
				path		: "/finance-a-r-invoice/:id?",
				name		: tl(Cookies.get("lang"),"FinanceARInvoice"),
				menu		: "AR02",
				icon 		: InvoiceIcon,
				component 	: FinanceARInvoiceActivity,
				layout		: "/admin"
			},
			{
				path		: "/finance-a-r-debit-note/:id?",
				name		: tl(Cookies.get("lang"),"FinanceARDebitNote"),
				menu		: "AR04",
				icon 		: InvoiceIcon,
				component 	: FinanceARDebitNoteActivity,
				layout		: "/admin"
			},
			{
				path		: "/finance-a-r-consignment-sales/:id?",
				name		: tl(Cookies.get("lang"),"FinanceARConsignmentSales"),
				menu		: "AR12",
				icon 		: InvoiceIcon,
				component 	: FinanceARConsignmentSales,
				layout		: "/admin"
			},
			{
				path		: "/finance-a-r-collective-invoice/:id?",
				name		: tl(Cookies.get("lang"),"FinanceARCollectiveInvoice"),
				menu		: "AR03",
				icon 		: CollectiveInvoiceIcon,
				component 	: FinanceARCollectiveInvoiceActivity,
				layout		: "/admin"
			},
			{
				path		: "/finance-a-r-receive/:id?",
				name		: tl(Cookies.get("lang"),"FinanceARReceive"),
				menu		: "AR05",
				icon 		: PaymentIcon,
				component 	: FinanceARReceiveActivity,
				layout		: "/admin"
			},
			{
				path		: "/finance-a-r-return/:id?",
				name		: tl(Cookies.get("lang"),"FinanceARReturn"),
				menu		: "AR010",
				icon 		: ReturnIcon,
				component 	: FinanceARReturnActivity,
				layout		: "/admin"
			},
			{
				path		: "/finance-a-r-payable-claim/:id?",
				name		: tl(Cookies.get("lang"),"FinanceARPayableClaim"),
				menu		: "AR011",
				icon 		: SalesAdjustmentIcon,
				component 	: FinanceARPayableClaimActivity,
				layout		: "/admin"
			},
			{
				path		: "/finance-a-r-write-off/:id?",
				name		: tl(Cookies.get("lang"),"FinanceARWriteOff"),
				menu		: "AR06",
				icon 		: WriteOffIcon,
				component 	: FinanceARWriteOffActivity,
				layout		: "/admin"
			},
			{
				path		: "/finance-a-r-advance-receive/:id?",
				name		: tl(Cookies.get("lang"),"FinanceARAdvanceReceive"),
				menu		: "AR07",
				icon 		: PaymentIcon,
				component 	: FinanceARAdvanceReceiveActivity,
				layout		: "/admin"
			},
			{
				path		: "/finance-a-r-giro/:id?",
				name		: tl(Cookies.get("lang"),"FinanceARGiro"),
				menu		: "AR08",
				icon 		: GiroIcon,
				component 	: FinanceARGiroActivity,
				layout		: "/admin"
			},
			{
				path		: "/finance-a-r-customer/:id?",
				name		: tl(Cookies.get("lang"),"FinanceARCustomer"),
				menu		: "AR09",
				icon 		: CustomerIcon,
				component 	: FinanceARCustomerActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Finance AP"),
	    menu		: "AP",
	    icon 		: WalletIcon,
	    state		: "AP",
	    views		:[
			{
				path		: "/finance-a-p-goods-receive-note/:id?",
				name		: tl(Cookies.get("lang"),"FinanceAPGoodsReceiveNote"),
				menu		: "AP01",
				icon 		: GoodsReceiveIcon,
				component 	: FinanceAPGoodsReceiveNoteActivity,
				layout		: "/admin",
				// widget		: "components/Accounting/GoodsReceiveNotes/GoodsReceiveNotesBrowser"
				widget 		: "components/Accounting/GoodsReceiveNote/GoodsReceiveNoteBrowser"
			},
			{
				path		: "/finance-a-p-invoice/:id?",
				name		: tl(Cookies.get("lang"),"FinanceAPInvoice"),
				menu		: "AP02",
				icon 		: InvoiceIcon,
				component 	: FinanceAPInvoiceActivity,
				layout		: "/admin",
				widget		: "components/Accounting/Invoice/InvoiceBrowser#FinanceAPInvoice"
			},
			{
				path		: "/finance-a-p-credit-note/:id?",
				name		: tl(Cookies.get("lang"),"FinanceAPCreditNote"),
				menu		: "AP04",
				icon 		: InvoiceIcon,
				component 	: FinanceAPCreditNoteActivity,
				layout		: "/admin"
			},
			{
				path		: "/finance-a-p-consignment-purchase/:id?",
				name		: tl(Cookies.get("lang"),"FinanceAPConsignmentPurchase"),
				menu		: "AP11",
				icon 		: InvoiceIcon,
				component 	: FinanceAPConsignmentPurchase,
				layout		: "/admin"
			},
			{
				path		: "/finance-a-p-collective-invoice/:id?",
				name		: tl(Cookies.get("lang"),"FinanceAPCollectiveInvoice"),
				menu		: "AP03",
				icon 		: CollectiveInvoiceIcon,
				component 	: FinanceAPCollectiveInvoiceActivity,
				layout		: "/admin"
			},
			{
				path		: "/finance-a-p-payment/:id?",
				name		: tl(Cookies.get("lang"),"FinanceAPPayment"),
				menu		: "AP05",
				icon 		: PaymentIcon,
				component 	: FinanceAPPaymentActivity,
				layout		: "/admin"
			},
			{
				path		: "/bulk-payment/:id?",
				name		: tl(Cookies.get("lang"),"BulkPayment"),
				menu		: "AP12",
				icon 		: PaymentIcon,
				component 	: BulkPaymentActivity,
				layout		: "/admin"
			},
			{
				path		: "/finance-a-p-return/:id?",
				name		: tl(Cookies.get("lang"),"FinanceAPReturn"),
				menu		: "AP010",
				icon 		: ReturnIcon,
				component 	: FinanceAPReturnActivity,
				layout		: "/admin"
			},
			{
				path		: "/finance-a-p-purchase-write-off/:id?",
				name		: tl(Cookies.get("lang"),"FinanceAPPurchaseWriteOff"),
				menu		: "AP06",
				icon 		: WriteOffIcon,
				component 	: FinanceAPPurchaseWriteOffActivity,
				layout		: "/admin"
			},
			{
				path		: "/finance-a-p-advance-payment/:id?",
				name		: tl(Cookies.get("lang"),"FinanceAPAdvancePayment"),
				menu		: "AP07",
				icon 		: PaymentIcon,
				component 	: FinanceAPAdvancePaymentActivity,
				layout		: "/admin"
			},
			{
				path		: "/finance-a-p-giro/:id?",
				name		: tl(Cookies.get("lang"),"FinanceAPGiro"),
				menu		: "AP08",
				icon 		: GiroIcon,
				component 	: FinanceAPGiroActivity,
				layout		: "/admin"
			},
			{
				path		: "/finance-a-p-supplier/:id?",
				name		: tl(Cookies.get("lang"),"FinanceAPSupplier"),
				menu		: "AP09",
				icon 		: CustomerIcon,
				component 	: FinanceAPSupplierActivity,
				layout		: "/admin"
			},
			{
				path		: "/finance-a-p-withdraw-request/:id?",
				name		: tl(Cookies.get("lang"),"FinanceAPWithdrawRequest"),
				menu		: "AP09",
				icon 		: WithdrawIcon,
				component 	: FinanceAPWithdrawRequestActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"AR Report"),
	    menu		: "ARREPORT",
	    icon 		: WalletIcon,
	    state		: "ARREPORT",
	    views		:[
			{
				path		: "/a-r-report-invoice",
				name		: tl(Cookies.get("lang"),"ARReportInvoice"),
				menu		: "ARREPORT01",
				icon 		: ExcelIcon,
				component 	: ARReportInvoiceActivity,
				layout		: "/admin"
			},
			{
				path		: "/a-r-report-receive",
				name		: tl(Cookies.get("lang"),"ARReportReceive"),
				menu		: "ARREPORT02",
				icon 		: ExcelIcon,
				component 	: ARReportReceiveActivity,
				layout		: "/admin"
			},
			{
				path		: "/a-r-report-outstanding-giro",
				name		: tl(Cookies.get("lang"),"ARReportOutstandingGiro"),
				menu		: "ARREPORT03",
				icon 		: ExcelIcon,
				component 	: ARReportOutstandingGiroActivity,
				layout		: "/admin"
			},
			{
				path		: "/a-r-report-outstanding-delivery-order",
				name		: tl(Cookies.get("lang"),"ARReportOutstandingDeliveryOrder"),
				menu		: "ARREPORT04",
				icon 		: ExcelIcon,
				component 	: ARReportOutstandingDeliveryOrderActivity,
				layout		: "/admin"
			},
			{
				path		: "/a-r-report-outstanding-invoice",
				name		: tl(Cookies.get("lang"),"ARReportOutstandingInvoice"),
				menu		: "ARREPORT05",
				icon 		: ExcelIcon,
				component 	: ARReportOutstandingInvoiceActivity,
				layout		: "/admin"
			},
			{
				path		: "/a-r-report-customer-credit-limit",
				name		: tl(Cookies.get("lang"),"ARReportCustomerCreditLimit"),
				menu		: "ARREPORT06",
				icon 		: ExcelIcon,
				component 	: ARReportCustomerCreditLimitActivity,
				layout		: "/admin"
			},
			{
				path		: "/a-r-report-return",
				name		: tl(Cookies.get("lang"),"ARReportReturn"),
				menu		: "ARREPORT07",
				icon 		: ExcelIcon,
				component 	: ARReportReturnActivity,
				layout		: "/admin"
			},
			{
				path		: "/a-r-report-aging/:id?",
				name		: tl(Cookies.get("lang"),"ARAgingReport"),
				menu		: "ARREPORT08",
				icon 		: ExcelIcon,
				component 	: ARReportAgingActivity,
				layout		: "/admin"
			},
			{
				path		: "/a-r-detailed-voucher/:id?",
				name		: tl(Cookies.get("lang"),"ARDetailedReportActivity"),
				menu		: "ARREPORT09",
				icon 		: ExcelIcon,
				component 	: ARDetailedVoucherReportActivity,
				layout		: "/admin"
			},
			{
				path		: "/a-r-invoice-movement/:id?",
				name		: tl(Cookies.get("lang"),"ARInvoiceMovementReport"),
				menu		: "ARREPORT10",
				icon 		: ExcelIcon,
				component 	: ARInvoiceMovementReportActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"AP Report"),
	    menu		: "APREPORT",
	    icon 		: WalletIcon,
	    state		: "APREPORT",
	    views		:[
			{
				path		: "/a-p-report-invoice",
				name		: tl(Cookies.get("lang"),"APReportInvoice"),
				menu		: "APREPORT01",
				icon 		: ExcelIcon,
				component 	: APReportInvoiceActivity,
				layout		: "/admin"
			},
			{
				path		: "/a-p-report-payment",
				name		: tl(Cookies.get("lang"),"APReportPayment"),
				menu		: "APREPORT02",
				icon 		: ExcelIcon,
				component 	: APReportPaymentActivity,
				layout		: "/admin"
			},
			{
				path		: "/a-p-report-outstanding-giro",
				name		: tl(Cookies.get("lang"),"APReportOutstandingGiro"),
				menu		: "APREPORT03",
				icon 		: ExcelIcon,
				component 	: APReportOutstandingGiroActivity,
				layout		: "/admin"
			},
			{
				path		: "/a-p-report-outstanding-goods-receive-note",
				name		: tl(Cookies.get("lang"),"APReportOutstandingGoodsReceiveNote"),
				menu		: "APREPORT04",
				icon 		: ExcelIcon,
				component 	: APReportOutstandingGoodsReceiveNoteActivity,
				layout		: "/admin"
			},
			{
				path		: "/a-p-report-outstanding-invoice",
				name		: tl(Cookies.get("lang"),"APReportOutstandingInvoice"),
				menu		: "APREPORT05",
				icon 		: ExcelIcon,
				component 	: APReportOutstandingInvoiceActivity,
				layout		: "/admin"
			},
			{
				path		: "/a-p-report-return",
				name		: tl(Cookies.get("lang"),"APReportReturn"),
				menu		: "APREPORT06",
				icon 		: ExcelIcon,
				component 	: APReportReturnActivity,
				layout		: "/admin"
			},
			{
				path		: "/a-p-report-aging/:id?",
				name		: tl(Cookies.get("lang"),"APAgingReport"),
				menu		: "APREPORT07",
				icon 		: ExcelIcon,
				component 	: APReportAgingActivity,
				layout		: "/admin"
			},
			{
				path		: "/a-p-detailed-voucher/:id?",
				name		: tl(Cookies.get("lang"),"APDetailedReportActivity"),
				menu		: "APREPORT08",
				icon 		: ExcelIcon,
				component 	: APDetailedVoucherReportActivity,
				layout		: "/admin"
			},
			{
				path		: "/a-p-invoice-movement/:id?",
				name		: tl(Cookies.get("lang"),"APInvoiceMovementReport"),
				menu		: "APREPORT09",
				icon 		: ExcelIcon,
				component 	: APInvoiceMovementReportActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Finance Config"),
	    menu		: "FINCONF",
	    icon 		: WalletIcon,
	    state		: "FINCONF",
	    views		:[
			{
				path		: "/finance-config-term/:id?",
				name		: tl(Cookies.get("lang"),"FinanceConfigTerm"),
				menu		: "FINCONF01",
				icon 		: DashboardIcon,
				component 	: FinanceConfigTermActivity,
				layout		: "/admin"
			},
			{
				path		: "/finance-config-token-movement/:id?",
				name		: tl(Cookies.get("lang"),"FinanceConfigTokenMovement"),
				menu		: "FINCONF02",
				icon 		: DashboardIcon,
				component 	: FinanceConfigTokenMovementActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Warehouse"),
	    menu		: "WRH",
	    icon 		: BranchIcon,
	    state		: "WRH",
	    views		:[
			{
				path		: "/warehouse-goods-receive-note/:id?",
				name		: tl(Cookies.get("lang"),"WarehouseGoodsReceiveNote"),
				menu		: "WRH01",
				icon 		: GoodsReceiveIcon,
				component 	: WarehouseGoodsReceiveNoteActivity,
				layout		: "/admin"
			},
			{
				path		: "/warehouse-delivery-order/:id?",
				name		: tl(Cookies.get("lang"),"WarehouseDeliveryOrder"),
				menu		: "WRH02",
				icon 		: ShippingIcon,
				component 	: WarehouseDeliveryOrderActivity,
				layout		: "/admin"
			},
			{
				path		: "/warehouse-cross-branch-delivery-order/:id?",
				name		: tl(Cookies.get("lang"),"WarehouseCrossBranchDeliveryOrderActivity"),
				menu		: "WRH09",
				icon 		: ShippingIcon,
				component 	: WarehouseCrossBranchDeliveryOrderActivity,
				layout		: "/admin"
			},
			{
				path		: "/warehouse-sales-return/:id?",
				name		: tl(Cookies.get("lang"),"WarehouseSalesReturn"),
				menu		: "WRH03",
				icon 		: ReturnIcon,
				component 	: WarehouseSalesReturnActivity,
				layout		: "/admin"
			},
			{
				path		: "/warehouse-purchase-return/:id?",
				name		: tl(Cookies.get("lang"),"WarehousePurchaseReturn"),
				menu		: "WRH05",
				icon 		: ReturnIcon,
				component 	: WarehousePurchaseReturnActivity,
				layout		: "/admin"
			},
			{
				path		: "/warehouse-material-release/:id?",
				name		: tl(Cookies.get("lang"),"WarehouseMaterialRelease"),
				menu		: "WRH04",
				icon 		: MaterialReleaseIcon,
				component 	: WarehouseMaterialReleaseActivity,
				layout		: "/admin"
			},
			{
				path		: "/warehouse-inventory-issue/:id?",
				name		: tl(Cookies.get("lang"),"WarehouseInventoryIssue"),
				menu		: "WRH06",
				icon 		: MaterialReleaseIcon,
				component 	: WarehouseInventoryIssueActivity,
				layout		: "/admin"
			},
			{
				path		: "/warehouse-inventory-adjustment/:id?",
				name		: tl(Cookies.get("lang"),"WarehouseInventoryAdjustment"),
				menu		: "WRH07",
				icon 		: AdjustmentIcon,
				component 	: WarehouseInventoryAdjustmentActivity,
				layout		: "/admin"
			},
			{
				path		: "/warehouse-inventory-relocation/:id?",
				name		: tl(Cookies.get("lang"),"WarehouseInventoryRelocation"),
				menu		: "WRH08",
				icon 		: RelocationIcon,
				component 	: WarehouseInventoryRelocationActivity,
				layout		: "/admin"
			},
			{
				path		: "/warehouse-inventory-conversion/:id?",
				name		: tl(Cookies.get("lang"),"InventoryConversion"),
				menu		: "WRHINVCONV",
				icon 		: UnitConversionIcon,
				component 	: WarehouseInventoryConversionActivity,
				layout		: "/admin"
			},
			{
				path		: "/warehouse-asset/:id?",
				name		: tl(Cookies.get("lang"),"Asset"),
				menu		: "WRHAST",
				icon 		: JournalIcon,
				component 	: AccountingAssetActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Warehouse Report"),
	    menu		: "WRHREPORT",
	    icon 		: BranchIcon,
	    state		: "WRHREPORT",
	    views		:[
			{
				path		: "/warehouse-report-item",
				name		: tl(Cookies.get("lang"),"WarehouseReportItem"),
				menu		: "WRHREPORT01",
				icon 		: ExcelIcon,
				component 	: WarehouseReportItemActivity,
				layout		: "/admin"
			},
			{
				path		: "/warehouse-report-daily-stock",
				name		: tl(Cookies.get("lang"),"WarehouseReportDailyStock"),
				menu		: "WRHREPORT02",
				icon 		: ExcelIcon,
				component 	: WarehouseReportDailyStockActivity,
				layout		: "/admin",
				widget 		: "modules/Accounting/reports/DailyStockReport/WarehouseReportDailyStock"
			},
			{
				path		: "/warehouse-report-stock",
				name		: tl(Cookies.get("lang"),"WarehouseReportStock"),
				menu		: "WRHREPORT03",
				icon 		: ExcelIcon,
				component 	: WarehouseReportStockActivity,
				layout		: "/admin"
			},
			{
				path		: "/warehouse-report-goods-receive",
				name		: tl(Cookies.get("lang"),"WarehouseReportGoodsReceive"),
				menu		: "WRHREPORT04",
				icon 		: ExcelIcon,
				component 	: WarehouseReportGoodsReceiveActivity,
				layout		: "/admin"
			},
			{
				path		: "/warehouse-report-delivery-order",
				name		: tl(Cookies.get("lang"),"WarehouseReportDeliveryOrder"),
				menu		: "WRHREPORT05",
				icon 		: ExcelIcon,
				component 	: WarehouseReportDeliveryOrderActivity,
				layout		: "/admin"
			},
			{
				path		: "/warehouse-report-return",
				name		: tl(Cookies.get("lang"),"WarehouseReportReturn"),
				menu		: "WRHREPORT06",
				icon 		: ExcelIcon,
				component 	: WarehouseReportReturnActivity,
				layout		: "/admin"
			},
			{
				path		: "/warehouse-report-inventory-conversion",
				name		: tl(Cookies.get("lang"),"InventoryConversionReport"),
				menu		: "WRHREPORT07",
				icon 		: ExcelIcon,
				component 	: WarehouseReportInventoryConversionActivity,
				layout		: "/admin"
			},
			{
				path		: "/warehouse-report-inventory-relocation",
				name		: tl(Cookies.get("lang"),"InventoryRelocationReport"),
				menu		: "WRHREPORT08",
				icon 		: ExcelIcon,
				component 	: WarehouseReportInventoryRelocationActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Warehouse Config"),
	    menu		: "WRHCONF",
	    icon 		: BranchIcon,
	    state		: "WRHCONF",
	    views		:[
			{
				path		: "/warehouse-config-location/:id?",
				name		: tl(Cookies.get("lang"),"WarehouseConfigLocation"),
				menu		: "WRHCONF01",
				icon 		: LocationIcon,
				component 	: WarehouseConfigLocationActivity,
				layout		: "/admin"
			},
			{
				path		: "/warehouse-config-warehouse/:id?",
				name		: tl(Cookies.get("lang"),"WarehouseConfigWarehouse"),
				menu		: "WRHCONF02",
				icon 		: BranchIcon,
				component 	: WarehouseConfigWarehouseActivity,
				layout		: "/admin"
			},
			{
				path		: "/warehouse-config-item/:id?",
				name		: tl(Cookies.get("lang"),"WarehouseConfigItem"),
				menu		: "WRHCONF03",
				icon 		: ItemIcon,
				component 	: WarehouseConfigItemActivity,
				layout		: "/admin"
			},
			{
				path		: "/warehouse-config-item-category/:id?",
				name		: tl(Cookies.get("lang"),"WarehouseConfigItemCategory"),
				menu		: "WRHCONF04",
				icon 		: CategoryIcon,
				component 	: WarehouseConfigItemCategoryActivity,
				layout		: "/admin"
			},
			{
				path		: "/promotion/:id?",
				name		: tl(Cookies.get("lang"),"Promotion"),
				menu		: "WRHCONF06",
				icon 		: PromotionIcon,
				component 	: PromotionActivity,
				layout		: "/admin"
			},
			{
				path		: "/warehouse-config-unit/:id?",
				name		: tl(Cookies.get("lang"),"WarehouseConfigUnit"),
				menu		: "WRHCONF05",
				icon 		: UnitIcon,
				component 	: WarehouseConfigUnitActivity,
				layout		: "/admin"
			},
			{
				path		: "/warehouse-config-item-import",
				name		: tl(Cookies.get("lang"),"ItemImport"),
				menu		: "WRHCONFIMPT",
				icon 		: ImportIcon,
				component 	: WarehouseItemImportActivity,
				layout		: "/admin"
			},
		]
	},
	{
		collapse 	: true,
		name 		: tl("Procurement"),
		menu 		: "PROC",
		icon 		: ProcurementIcon,
		state 		: "PROC",
		views 		: [
			{
				path		: "/item-request/:id?",
				name		: tl(Cookies.get("lang"),"ItemRequest"),
				menu		: "WRHITEMREQ",
				icon 		: ItemRequestIcon,
				component 	: ProductionItemRequestActivity,
				layout		: "/admin"
			},
			{
				path		: "/item-request-completed/:id?",
				name		: tl(Cookies.get("lang"),"ItemRequestCompleted"),
				menu		: "PROCIRCOMP",
				icon 		: ItemRequestCompletedIcon,
				component 	: ProductionItemRequestCompletedActivity,
				layout		: "/admin"
			},
			{
				path		: "/item-request-cancelled/:id?",
				name		: tl(Cookies.get("lang"),"ItemRequestCancelled"),
				menu		: "PROCIRCNCL",
				icon 		: ItemRequestCancelledIcon,
				component 	: ProductionItemRequestCancelledActivity,
				layout		: "/admin"
			},
			{
				path		: "/purchasing-procurement-report",
				name		: tl(Cookies.get("lang"),"ProcurementReport"),
				menu		: "PURREPORT01",
				icon 		: ExcelIcon,
				component 	: ProcurementReportActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Sales"),
	    menu		: "SLS",
	    icon 		: SalesIcon,
	    state		: "SLS",
	    views		:[
	    	{
				path		: "/instant-sales",
				name		: tl(Cookies.get("lang"),"FinanceARInstantSales"),
				menu		: "SLS08",
				icon 		: InstantSalesIcon,
				component 	: InstantSales,
				layout		: "/admin"
			},
			{
				path		: "/instant-sales-browse/:id?",
				name		: tl(Cookies.get("lang"),"FinanceARInstantSalesBrowse"),
				menu		: "SLS08",
				icon 		: InstantSalesIcon,
				component 	: InstantSalesView,
				layout		: "/admin",
				widget  	: "components/Accounting/InstantTrx/InstantTrxBrowser#sales"
			},
	    	{
				path		: "/sales-sales-order/:id?",
				name		: tl(Cookies.get("lang"),"SalesSalesOrder"),
				menu		: "SLS06",
				icon 		: SalesOrderIcon,
				component 	: SalesSalesOrderActivity,
				layout		: "/admin"
			},
			{
				path		: "/sales-order-extend/:id?",
				name		: tl(Cookies.get("lang"),"SalesOrderExtend"),
				menu		: "SLS07",
				icon 		: SalesOrderExtendIcon,
				component 	: SalesOrderExtendActivity,
				layout		: "/admin"
			},
			{
				path		: "/sales-delivery-order/:id?",
				name		: tl(Cookies.get("lang"),"SalesDeliveryOrder"),
				menu		: "SLS01",
				icon 		: ShippingIcon,
				component 	: SalesDeliveryOrderActivity,
				layout		: "/admin"
			},
			{
				path		: "/sales-customer/:id?",
				name		: tl(Cookies.get("lang"),"SalesCustomer"),
				menu		: "SLS09",
				icon 		: CustomerIcon,
				component 	: SalesCustomerActivity,
				layout		: "/admin"
			},
			{
				path		: "/sales-member/:id?",
				name		: tl(Cookies.get("lang"),"SalesMember"),
				menu		: "SLS11",
				icon 		: MemberIcon,
				component 	: SalesMemberActivity,
				layout		: "/admin"
			},
			{
				path		: "/sales-item/:id?",
				name		: tl(Cookies.get("lang"),"SalesItem"),
				menu		: "SLS02",
				icon 		: ItemIcon,
				component 	: SalesItemActivity,
				layout		: "/admin"
			},
			{
				path		: "/sales-item-category/:id?",
				name		: tl(Cookies.get("lang"),"SalesItemCategory"),
				menu		: "SLS03",
				icon 		: CategoryIcon,
				component 	: SalesItemCategoryActivity,
				layout		: "/admin"
			},
			{
				path		: "/sales-unit/:id?",
				name		: tl(Cookies.get("lang"),"SalesUnit"),
				menu		: "SLS04",
				icon 		: UnitIcon,
				component 	: SalesUnitActivity,
				layout		: "/admin"
			},
		]
	},
	{
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"SalesReport"),
	    menu		: "SLSREPORT",
	    icon 		: SalesIcon,
	    state		: "SLSREPORT",
	    views		:[
			{
				path		: "/sales-report-item-pricelist",
				name		: tl(Cookies.get("lang"),"SalesReportItemPricelist"),
				menu		: "SLSREPORT01",
				icon 		: ExcelIcon,
				component 	: ItemPricelistActivity,
				layout		: "/admin"
			},
			{
				path		: "/sales-report-instant-sales",
				name		: tl(Cookies.get("lang"),"InstantSalesReport"),
				menu		: "SLSREPORT02",
				icon 		: ExcelIcon,
				component 	: InstantSalesReportActivity,
				layout		: "/admin"
			},
			{
				path		: "/sales-report-commission",
				name		: tl(Cookies.get("lang"),"CommissionReport"),
				menu		: "SLSRPTCOM",
				icon 		: ExcelIcon,
				component 	: CommissionReportActivity,
				layout		: "/admin"
			},
			{
				path		: "/sales-report-item-statistic",
				name		: tl(Cookies.get("lang"),"ItemStatisticReport"),
				menu		: "SLSREPORT03",
				icon 		: ExcelIcon,
				component 	: ItemStatisticReportActivity,
				layout		: "/admin"
			},
			{
				path		: "/sales-report-cashier",
				name		: tl(Cookies.get("lang"),"CashierReport"),
				menu		: "SLSRPTCASH",
				icon 		: ExcelIcon,
				component 	: CashierReportActivity,
				layout		: "/admin"
			},
			{
				path		: "/sales-report-item-price-sales-vs-purchase",
				name		: tl(Cookies.get("lang"),"ItemPriceSalesPurchase"),
				menu		:  "SLSRPTSVP",
				icon 		: ExcelIcon,
				component 	: ItemPriceSalesPurchase,
				layout		: "/admin"
			},
			{
				path		: "/sales-warning-log-report",
				name		: tl(Cookies.get("lang"),"WarningLog"),
				menu		: "SLSRPTWLOG",
				icon 		: ExcelIcon,
				component 	: WarningLogReportActivity,
				layout		: "/admin"
			},
			{
				path		: "/sales-pricelist-report",
				name		: tl(Cookies.get("lang"),"Pricelist"),
				menu		: "SLSRPTPRCL",
				icon 		: ExcelIcon,
				component 	: PricelistReportActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Purchasing"),
	    menu		: "PUR",
	    icon 		: PurchaseIcon,
	    state		: "PUR",
	    views		:[
			{
				path		: "/instant-purchase",
				name		: tl(Cookies.get("lang"),"InstantPurchase"),
				menu		: "PUR01",
				icon 		: InstantPurchaseIcon,
				component 	: FinanceAPInstantPurchase,
				layout		: "/admin"
			},
			{
				path		: "/instant-purchase-browse/:id?",
				name		: tl(Cookies.get("lang"),"FinanceAPInstantPurchaseBrowse"),
				menu		: "PUR01",
				icon 		: InstantSalesIcon,
				component 	: InstantPurchaseView,
				layout		: "/admin"
			},
			// {
			// 	path		: "/purchasing-purchase-order/:id?",
			// 	name		: tl(Cookies.get("lang"),"PurchasingPurchaseOrder"),
			// 	menu		: "PUR01",
			// 	icon 		: POIcon,
			// 	component 	: PurchasingPurchaseOrderActivity,
			// 	layout		: "/admin"
			// },
			{
				path		: "/purchasing-purchase-order-advanced/:id?",
				name		: tl(Cookies.get("lang"),"PurchaseOrder"),
				menu		: "PURORDADV",
				icon 		: POIcon,
				component 	: FinanceAPPurchaseOrderAdvancedActivity,
				layout		: "/admin"
			},
			{
				path		: "/purchasing-goods-receive-note/:id?",
				name		: tl(Cookies.get("lang"),"PurchasingGoodsReceiveNote"),
				menu		: "PUR02",
				icon 		: GoodsReceiveIcon,
				component 	: PurchasingGoodsReceiveNoteActivity,
				layout		: "/admin"
			},
			{
				path		: "/purchasing-vendor-delivery-request/:id?",
				name		: tl(Cookies.get("lang"),"PurchasingVendorDeliveryRequest"),
				menu		: "PUR07",
				icon 		: ShippingIcon,
				component 	: PurchasingVendorDeliveryRequestActivity,
				layout		: "/admin"
			},
			{
				path		: "/purchasing-supplier/:id?",
				name		: tl(Cookies.get("lang"),"PurchasingSupplier"),
				menu		: "PUR03",
				icon 		: CustomerIcon,
				component 	: PurchasingSupplierActivity,
				layout		: "/admin"
			},
			{
				path		: "/purchasing-item/:id?",
				name		: tl(Cookies.get("lang"),"PurchasingItem"),
				menu		: "PUR04",
				icon 		: ItemIcon,
				component 	: PurchasingItemActivity,
				layout		: "/admin"
			},
			{
				path		: "/purchasing-item-category/:id?",
				name		: tl(Cookies.get("lang"),"PurchasingItemCategory"),
				menu		: "PUR05",
				icon 		: CategoryIcon,
				component 	: PurchasingItemCategoryActivity,
				layout		: "/admin"
			},
			{
				path		: "/purchasing-unit/:id?",
				name		: tl(Cookies.get("lang"),"PurchasingUnit"),
				menu		: "PUR06",
				icon 		: UnitIcon,
				component 	: PurchasingUnitActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Purchasing Report"),
	    menu		: "PURREPORT",
	    icon 		: PurchaseIcon,
	    state		: "PURREPORT",
	    views		:[
			{
				path		: "/purchasing-instant-purchase-item-prices-report",
				name		: tl(Cookies.get("lang"),"InstantPurchaseItemPricesReport"),
				menu		: "PURREPORT01",
				icon 		: ExcelIcon,
				component 	: InstantPurchaseItemPricesReportActivity,
				layout		: "/admin"
			},
			{
				path		: "/purchasing-instant-purchase-report",
				name		: tl(Cookies.get("lang"),"InstantPurchaseReport"),
				menu		: "PURREPORT01",
				icon 		: ExcelIcon,
				component 	: InstantPurchaseReportActivity,
				layout		: "/admin"
			},
			{
				path		: "/purchasing-report-outstanding-purchase-order",
				name		: tl(Cookies.get("lang"),"PurchasingReportOutstandingPurchaseOrder"),
				menu		: "PURREPORT01",
				icon 		: ExcelIcon,
				component 	: PurchasingReportOutstandingPurchaseOrderActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Project"),
	    menu		: "PRJ",
	    icon 		: ProjectIcon,
	    state		: "PRJ",
	    views		:[
			{
				path		: "/project-delivery-request/:id?",
				name		: tl(Cookies.get("lang"),"ProjectDeliveryRequest"),
				menu		: "PRJ01",
				icon 		: ShippingIcon,
				component 	: ProjectDeliveryRequestActivity,
				layout		: "/admin"
			},
			{
				path		: "/project-project/:id?",
				name		: tl(Cookies.get("lang"),"ProjectProject"),
				menu		: "PRJ02",
				icon 		: ProjectIcon,
				component 	: ProjectProjectActivity,
				layout		: "/admin"
			},
		]
	},
	{
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Production"),
	    menu		: "PRD",
	    icon 		: ProductionIcon,
	    state		: "PRD",
	    views		:[
			{
				path		: "/production-item-request/:id?",
				name		: tl(Cookies.get("lang"),"ProductionItemRequest"),
				menu		: "PRD01",
				icon 		: ItemRequestIcon,
				component 	: ProductionItemRequestActivity,
				layout		: "/admin"
			},
			{
				path		: "/production-production-result/:id?",
				name		: tl(Cookies.get("lang"),"ProductionProductionResult"),
				menu		: "PRD02",
				icon 		: ProductionResultIcon,
				component 	: ProductionProductionResultActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"HR"),
	    menu		: "HR",
	    icon 		: HumanIcon,
	    state		: "HR",
	    views		:[
			{
				path		: "/h-r-employee/:id?",
				name		: tl(Cookies.get("lang"),"HREmployee"),
				menu		: "HR01",
				icon 		: EmployeeIcon,
				component 	: HREmployeeActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Administration"),
	    menu		: "ADM",
	    icon 		: SettingsIcon,
	    state		: "ADM",
	    views		:[
			{
				path		: "/administration-user/:id?",
				name		: tl(Cookies.get("lang"),"AdministrationUser"),
				menu		: "ADM01",
				icon 		: UserIcon,
				component 	: AdministrationUserActivity,
				layout		: "/admin"
			},
			{
				path		: "/administration-user-group/:id?",
				name		: tl(Cookies.get("lang"),"AdministrationUserGroup"),
				menu		: "ADM02",
				icon 		: UserGroupIcon,
				component 	: AdministrationUserGroupActivity,
				layout		: "/admin"
			},
			{
				path		: "/administration-branch/:id?",
				name		: tl(Cookies.get("lang"),"AdministrationBranch"),
				menu		: "ADM03",
				icon 		: BranchIcon,
				component 	: AdministrationBranchActivity,
				layout		: "/admin"
			},
			{
				path		: "/administration-city/:id?",
				name		: tl(Cookies.get("lang"),"AdministrationCity"),
				menu		: "ADM04",
				icon 		: CityIcon,
				component 	: AdministrationCityActivity,
				layout		: "/admin"
			},
			{
				path		: "/administration-province/:id?",
				name		: tl(Cookies.get("lang"),"AdministrationProvince"),
				menu		: "ADM05",
				icon 		: LocationIcon,
				component 	: AdministrationProvinceActivity,
				layout		: "/admin"
			},
			{
				path		: "/administration-country/:id?",
				name		: tl(Cookies.get("lang"),"AdministrationCountry"),
				menu		: "ADM06",
				icon 		: CountryIcon,
				component 	: AdministrationCountryActivity,
				layout		: "/admin"
			},
			{
				path		: "/administration-node/:id?",
				name		: tl(Cookies.get("lang"),"AdministrationNode"),
				menu		: "ADM07",
				icon 		: NodeIcon,
				component 	: AdministrationNodeActivity,
				layout		: "/admin"
			},
			{
				path		: "/administration-menu/:id?",
				name		: tl(Cookies.get("lang"),"AdministrationMenu"),
				menu		: "ADM08",
				icon 		: MenuIcon,
				component 	: AdministrationMenuActivity,
				layout		: "/admin"
			},
			{
				path		: "/administration-menu-category/:id?",
				name		: tl(Cookies.get("lang"),"AdministrationMenuCategory"),
				menu		: "ADM09",
				icon 		: CategoryIcon,
				component 	: AdministrationMenuCategoryActivity,
				layout		: "/admin"
			},
			{
				path		: "/administration-access-right/:id?",
				name		: tl(Cookies.get("lang"),"AdministrationAccessRight"),
				menu		: "ADM10",
				icon 		: AccessRightIcon,
				component 	: AdministrationAccessRightActivity,
				layout		: "/admin"
			},
			{
				path		: "/administration-access-right-category/:id?",
				name		: tl(Cookies.get("lang"),"AdministrationAccessRightCategory"),
				menu		: "ADM11",
				icon 		: CategoryIcon,
				component 	: AdministrationAccessRightCategoryActivity,
				layout		: "/admin"
			},
		]
	},
	{
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Vendor"),
	    menu		: "VEND",
	    icon 		: VendorIcon,
	    state		: "VEND",
	    views		:[
			{
				path		: "/vendor-delivery-request/:id?",
				name		: tl(Cookies.get("lang"),"VendorDeliveryRequest"),
				menu		: "VEND01",
				icon 		: ShippingIcon,
				component 	: PurchasingVendorDeliveryRequestActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Debug"),
	    menu		: "DBG",
	    icon 		: DebugIcon,
	    state		: "DBG",
	    views		:[
			{
				path		: "/debug-item-value/:id?",
				name		: tl(Cookies.get("lang"),"DebugItemValue"),
				menu		: "DBG01",
				icon 		: DebugIcon,
				component 	: DebugItemValueActivity,
				layout		: "/admin"
			},
			{
				path		: "/debug-inventory",
				name		: tl(Cookies.get("lang"),"DebugInventory"),
				menu		: "DBG02",
				icon 		: DebugIcon,
				component 	: DebugInventoryActivity,
				layout		: "/admin"
			},
			{
				path		: "/debug-inventory-move-plan",
				name		: tl(Cookies.get("lang"),"DebugInventoryMovePlan"),
				menu		: "DBG03",
				icon 		: DebugIcon,
				component 	: DebugInventoryMovePlanActivity,
				layout		: "/admin"
			},
		]
	},
]
export default Routes
